<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Level Pegawai
              </span>
            </h3>
            <div class="card-toolbar">
              <button
                @click="openModal"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Tambah Level
              </button>
            </div>
          </div>
          <div class="card-body pt-4">
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
              :filter="filter"
              :items="currentLevelList.data"
              :fields="fields"
              :busy="isLoading"
              @filtered="onFiltered"
              show-empty
              responsive
            >
              <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
              <template #empty="scope">
                <h6 class="text-center">Tidak ada level ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada level ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Soal:
                </div>
                <div v-html="row.item.question"></div>
              </template>
              <template #cell(level)="data">
                {{ "level " + data.item.level }}
              </template>
              <template #cell(action)="data">
                <div class="d-flex justify-content-start">
                  <router-link
                    :to="`/level/${data.item._id}`"
                    class="navi-link"
                  >
                    <span class="navi-link cursor-pointer mr-4">
                      <span class="navi-icon">
                        <i class="fa fa-eye mt-1"></i>
                      </span>
                    </span>
                  </router-link>
                  <span
                    class="navi-link cursor-pointer mr-4"
                    @click="updateLevel(data.item)"
                  >
                    <span class="navi-icon">
                      <i class="flaticon2-edit"></i>
                    </span>
                  </span>
                  <span
                    class="navi-link cursor-pointer"
                    @click="deleteLevel(data.item)"
                  >
                    <span class="navi-icon">
                      <i class="flaticon2-trash"></i>
                    </span>
                  </span>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
        <b-modal
          :no-close-on-backdrop="true"
          @ok="saveLevel"
          v-model="modalLevel"
          centered
          hide-footer
          scrollable
          :title="form._id ? 'Edit Level' : 'Tambah Level'"
        >
          <validation-observer ref="formLevel">
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Nama Level</label>
              <div class="col-lg-12">
                <div>
                  <validation-provider
                    #default="{ errors }"
                    name="nama"
                    rules="required"
                  >
                    <input
                      class="form-control"
                      placeholder="Nama Level"
                      type="text"
                      ref="username"
                      v-model="form.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Tipe Level</label>
              <div class="col-lg-12">
                <div>
                  <validation-provider
                    #default="{ errors }"
                    name="tipe_level"
                    rules="required"
                  >
                    <vue-select
                      :clearable="false"
                      v-model="form.level"
                      class="style-chooser"
                      :reduce="(item) => item.value"
                      :options="optionTipeLevel"
                    ></vue-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
            </div>
            <!-- <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Induk Level</label>
                            <div class="col-12">
                                <div>
                                    <validation-provider #default="{ errors }" name="induk">
                                        <vue-select :filterable="false" @search="getOptionsInduk" :clearable="false"
                                            v-model="form.parent_id" class="style-chooser" :reduce="item => item.value"
                                            :options="optionLevel"></vue-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                            </div>
                        </div> -->
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-secondary"
                type="button"
                @click="modalLevel = false"
              >
                Batal
              </button>
              &nbsp;&nbsp;
              <button
                @click="saveLevel"
                class="btn btn-primary"
                :disabled="loadingSubmitLevel"
              >
                Simpan&nbsp;<b-spinner
                  v-if="loadingSubmitLevel"
                  small
                  label="Spinning"
                ></b-spinner>
              </button>
            </div>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";

import {
  GET_LEVEL,
  DELETE_LEVEL,
  STORE_LEVEL,
  GET_INDUK_LEVEL,
  UPDATE_LEVEL,
} from "@/core/services/store/level.module";
import Swal from "sweetalert2";

import { debounce } from "lodash";

const initForm = {
  name: "",
  parent_id: null,
  level: 1,
};

export default {
  name: "PaketSoalList",
  data() {
    return {
      fileImport: null,
      modalImport: false,
      isLoading: false,
      selectedMapel: null,
      modalLevel: false,
      selectedSoal: {},
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "level",
          label: "Level",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
          thStyle: { width: "10%" },
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        truefalse: "True/False",
        essay: "Essay",
      },
      exam_type: "Default",
      form: {
        ...initForm,
      },
      optionLevel: [
        {
          value: null,
          label: "Tidak Ada induk",
        },
      ],
      optionTipeLevel: [
        {
          value: 1,
          label: "1",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Level Pegawai" }]);
    this.getData();
  },
  watch: {
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    },
    exam_type: function () {
      this.getData();
    },
    modalLevel: function (newVal) {
      if (!newVal) {
        this.getData();
        this.form = initForm;
      }
    },
    "form.level": function (newVal) {
      this.getOptionsInduk();
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.soal.errors,
    }),
    ...mapGetters(["currentLevelList", "loadingSubmitLevel"]),
    options() {
      var opt = [];
      this.currentMapelList.forEach((mapel) => {
        opt.push({
          text:
            mapel.kurikulum.mapel.nama_mapel +
            " - Tingkat " +
            parseInt(mapel.kurikulum.semester / 2 + 1),
          value: mapel.id_kurikulum,
        });
      });
      return opt;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      const param = new URLSearchParams();
      param.append("limit", this.perPage);
      param.append("page", this.currentPage);
      param.append("where['level']", 1);
      this.$store
        .dispatch(GET_LEVEL, param.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentLevelList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteLevel(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus level ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_LEVEL, item._id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus level`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Hapus level`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    updateLevel(item) {
      this.modalLevel = true;
      this.form = item;
    },
    openModal() {
      this.modalLevel = true;
    },
    saveLevel() {
      this.$refs.formLevel.validate().then(async (success) => {
        if (success) {
          this.$store
            .dispatch(this.form?._id ? UPDATE_LEVEL : STORE_LEVEL, this.form)
            .then(() => {
              this.modalLevel = false;
              this.$bvToast.toast("berhasil menyimpan", {
                title: `Pemberitahuan`,
                variant: "success",
                solid: true,
              });
            })
            .catch((response) => {
              this.$bvToast.toast(response.data.message, {
                title: `Pemberitahuan`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    getOptionsInduk: debounce(function (search, loading) {
      if (loading) {
        loading(true);
      }
      const param = new URLSearchParams();
      param.append("limit", 100);
      param.append("page", 1);
      param.append("where['level']", this.form.level);

      if ((search?.length ?? 0) > 0) {
        param.append("where['name']", search);
      }

      this.$store
        .dispatch(GET_INDUK_LEVEL, param.toString())
        .then((response) => {
          if (loading) {
            loading(false);
          }
          const data = [];
          data.push({
            value: null,
            label: "Tidak Ada induk",
          });

          if (response?.data?.data?.length > 0) {
            response.data.data.map((item) => {
              data.push({
                value: item._id,
                label: item.name,
              });
            });
          }

          this.optionLevel = data;
        })
        .catch((response) => {
          if (loading) {
            loading(false);
          }
          this.$bvToast.toast(response?.data?.message ?? "terjadi kesalahan", {
            title: `Pemberitahuan`,
            variant: "danger",
            solid: true,
          });
        });
    }, 300),
  },
};
</script>
<style scoped>
.style-chooser >>> .vs__search::placeholder,
.style-chooser >>> .vs__dropdown-toggle,
.style-chooser >>> .vs__dropdown-menu {
  border: 1px solid #e4e6ef;
  font-size: 1rem;
  padding: 0.5rem 0.6rem;
  border-radius: 0.42rem;
}

.style-chooser >>> .vs__clear,
.style-chooser >>> .vs__open-indicator {
  fill: #394066;
  font-size: 0.1rem;
}
</style>
